import api from "@api";
import router from "@/router";
import { getSearchResultOnRow } from "@Ekcore/util";
import axiosIns from "@/libs/axios";

export default {
    state: {
        studentName: [],
        visitCountList: "",
        classes: [],
        students: [],
        studentDto: {
            name: "",
            password: "",
            newPassword: "",
            phoneNumber: "",
            email: "",
            birthdate: "",
            gender: "",
            isSub: false,
            universities: [],
        },
        statusStudent: [
            { value: true, text: "مفعل", color: "success" },
            { value: false, text: "غير مفعل", color: "danger" },
        ],
        StudentsCols: [
            {
                label: "اسم المستخدم",
                field: "name",
            },
            {
                label: "رقم الموبايل ",
                field: "phoneNumber",
            },
            {
                label: "البريد الإلكتروني ",
                field: "email",
            },
            {
                label: "عدد الإشتراكات ",
                type: "number",
                field: "codeCount",
            },
            {
                label: "تاريخ الإنشاء",
                field: "dateCreated",
            },
            {
                label: "حالة الاشتراك",
                type: "boolean",
                field: "isSub",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        headerDetails: [
            {
                label: "اسم الحزمة",
                field: "packageName",
            },
            {
                label: "تابعة ل",
                field: "years",
            },
            {
                label: "الرمز",
                field: "code",
            },
            {
                label: "تاريخ الاشتراك",
                field: "startDate",
            },
            {
                label: " اسم المندوب",
                field: "createdBy",
            },
            {
                label: "  القيمة الدفوعة",
                field: "price",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        filterDto: {},
        searchDto: {
            keys: [],
            query: "",
        },
        tempFiles: [],
    },
    getters: {
        studentList({ students }, getter, { filter }) {
            return students.filter((el) => {
                return getSearchResultOnRow(filter, el);
            });
        },
    },
    mutations: {
        Get_Students_List(state, payload) {
            state.students = payload.students;
            state.visitCountList = payload.visitCount;
        },
        Get_Students_Name(state, payload) {
            state.studentName = payload;
        },
        Get_Classes_Id(state, payload) {
            state.classes = payload;
        },
        Add_Students(state, payload) {
            state.students.unshift(payload);
        },
        Get_Details_Student(state, payload) {
            console.log("hi");
            state.studentDto = { ...payload };
        },
        Update_Student(state, payload) {
            state.studentDto = { ...payload };
        },
        Delete_Student(state, id) {
            state.students.splice(
                state.students.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Students(state, ids) {
            state.students = state.students.filter(
                (el) => !ids.includes(el.id)
            );
        },
        Set_Student_Dto(state, payload) {
            if (payload) Object.assign(state.studentDto, payload);
            else {
                state.studentDto = {
                    name: "",
                    password: "",
                    phoneNumber: "",
                    email: "",
                    birthdate: "",
                    gender: "",
                    isSub: false,
                    code: [],
                };
            }
        },
        ISBLOCK_Student(state, payload) {
            state.studentDto.isBlocked = payload;
        },
    },
    actions: {
        getStudentDetails({ commit }, id) {
            api.get(`Student/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Student", data);
            });
        },
        getStudentsList({ commit }, universityId, facultyId) {
            api.get(
                `Student/Dash/GetAll?facultyId=${
                    facultyId ?? ""
                }&universityId=${universityId ?? ""}`,
                ({ data }) => {
                    commit("Get_Students_List", data);
                }
            );
        },
        getStudentsListByFactualy({ commit }, facultyId) {
            api.get(
                `Student/Dash/GetAll?facultyId=${facultyId ?? ""}`,
                ({ data }) => {
                    commit("Get_Students_List", data);
                }
            );
        },
        getStudentsListByUniversity({ commit }, universityId) {
            api.get(
                `Student/Dash/GetAll?universityId=${universityId ?? ""}`,
                ({ data }) => {
                    commit("Get_Students_List", data);
                }
            );
        },
        getStudentsName({ commit }, facultyId) {
            let facQuery = "";
            if (facultyId) facQuery = `?facultyId=${facultyId}`;
            api.get(`Student/Dash/GetNames${facQuery}`, ({ data }) => {
                commit("Get_Students_Name", data);
            });
        },
        addStudents({ commit }, payload) {
            api.post(
                "Student/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Students", data);
                },
                {
                    success: "تم إضافة الطالب بنجاح",
                    error: true,
                }
            );
        },
        async ResetStudent({ commit }, studentId) {
            try {
                const response = await api.post(
                    `Student/Dash/ResetDeviceId?studentId=${studentId}`,
                    null,
                    () => {
                        commit("Reset_Student", studentId);
                        // router.go(-1);
                    },
                    {
                        // confirm: " هل تريد بالتأكيد حذف الطالب",
                        success: `تم تهيئة حماية الجوال للطالب، وتم تغيير كلمة المرور لرقم الهاتف يرجى إعادة تغييرها `,
                        error: "فشلت حماية الطالب ..يرجى اعادة المحاولة ",
                    },
                    []
                );
                return response;
            } catch (err) {
                return err;
            }
        },
        updateStudent({ commit }, payload) {
            api.post(
                "Student/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Student", data);
                    router.push("/admin/students");
                },
                {
                    success: "تم التعديل  بنجاح",
                    error: "فشل التعديل ",
                }
            );
        },
        async deleteStudent({ commit }, id) {
            try {
                const response = await api.delete(
                    `Student/Dash/Delete?id=${id}`,
                    () => {
                        commit("Delete_Student", id);
                        router.go(-1);
                    },
                    {
                        confirm: " هل تريد بالتأكيد حذف الطالب",
                        success: "تم حذف الطالب بنجاح",
                        error: "فشل حذف الطالب ..يرجى اعادة المحاولة ",
                    },
                    []
                );
                return response;
            } catch (err) {
                return err;
            }
        },

        deleteStudents({ commit }, ids) {
            api.delete(
                "Student/Dash/Delete",
                () => {
                    commit("Delete_Students", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الطلاب المحددة",
                    success: "تم حذف الطلاب بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        blockStudent({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_Student", true);
                },
                {
                    success: "تم حظر الطالب بنجاح",
                    error: "فشل الحظر  ..يرجى اعادة المحاولة ",
                }
            );
        },
        unblockStudent({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_Student", false);
                },
                {
                    success: "تم الغاء حظر الطالب بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة ",
                }
            );
        },

        ExportAllStudentsToExcel({ commit }) {
            return axiosIns
                .get(`Student/Dash/ExportAllStudentsToExcel`, {
                    responseType: "blob", // Set the response type to blob
                })
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });

                    const file_name = `Inventory_${new Date()
                        .toISOString()
                        .slice(0, 10)}.xlsx`;

                    const a = document.createElement("a");
                    a.href = window.URL.createObjectURL(blob);
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    // Commit an action after successful export
                    commit("ExportToExcel");

                    console.log("تم تحميل ملف المشتركين");
                })
                .catch((error) => {
                    console.error("فشل تحميل ملف المشتركين");
                    throw error; // Propagate the error for further handling if needed
                });
        },
    },
};
