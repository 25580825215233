import api from "@api";
import router from "@/router";
import axiosIns from "@/libs/axios";

export default {
    state: {
        filterVal: "",
        subjects: [],
        url: "",
        filterDto: {
            semesterId: "",
            yearId: "",
            facultyId: "",
            universityId: "",
            search: "",
        },

        subjectDto: {
            name: "",
            url: "",
            description: "",
            price: 0,
            semesterId: "",
            yearId: "",
            departmentId: "",
            universityId: "",
            facultyId: "",
        },

        subjectDetailDto: {
            id: "",
            name: "",
            url: "",
            description: "",
            price: 0,
            isHidden: false,
            semesterId: "",
            yearId: "",
            universityId: "",
            facultyId: "",
            departmentId: "",
            packagesCount: 0,
            courses: [],
        },
    },

    mutations: {
        Get_Subjects_List(state, payload) {
            state.subjects = payload;
        },
        Set_Sub(state, payload) {
            state.subjects = payload;
        },
        Add_Subjects(state, payload) {
            state.subjects.subjects.push(payload);
        },
        Add_Courses_Subjects(state, payload) {
            state.subjectDetailDto.courses.push(payload);
        },

        Get_Details_Subject(state, payload) {
            state.subjectDetailDto = { ...payload };
        },
        Update_Subject(state, payload) {
            state.subjectDetailDto = { ...payload };
        },
        Add_File(state, payload) {
            state.url = payload;
        },
        Filter_Course(state, payload) {
            state.filterVal = payload;
        },
        Reset_Subject(state) {
            state.subjectDto = {
                name: "",
                url: "",
                description: "",
                price: 0,
                semesterId: "",
                yearId: "",
                departmentId: "",
                universityId: "",
                facultyId: "",
            };
        },
        Delete_Subject(state, id) {
            state.subjects.splice(
                state.subjects.findIndex((item) => item.id == id),
                1
            );
        },
    },
    getters: {
        getUrl(state) {
            return state.url;
        },
        filterCourses(state) {
            if (state.filterVal) {
                return state.subjectDetailDto.courses.filter((ele) => {
                    return (
                        ele.name
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.videoCount
                            .toString()
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.documentCount
                            .toString()
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.order
                            .toString()
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase())
                    );
                });
            } else return state.subjectDetailDto.courses;
        },
    },

    actions: {
        getSubjectsList({ commit }, payload) {
            console.log(payload.pageSize);
            api.get(
                `Subject/Dash/GetAll`,
                ({ data }) => {
                    commit("Get_Subjects_List", data);
                },
                () => {},
                {
                    params: { ...payload },
                }
            );
        },
        getSubjects({ commit }) {
            api.get(
                "Subject/Dash/GetAll?PageIndex=1&PageSize=10",
                ({ data }) => {
                    commit("Set_Sub", data);
                }
            );
        },
        addFile({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Add_File", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        addSubject({ commit }, payload) {
            console.log(payload);
            return new Promise((resolve, reject) => {
                api.post(
                    "Subject/Dash/Add",
                    payload,
                    (res) => {
                        commit("Add_Subjects", res.data);
                        commit("Reset_Subject");
                        resolve(res.data);
                    },
                    {
                        success: "تم إضافة المادة بنجاح",
                        error: "فشل إضافة المادة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },

        getSubjectDetails({ commit }, id) {
            api.get(`Subject/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Subject", data);
            });
        },
        updateSubject({ commit }, payload) {
            api.post(
                "Subject/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Subject", data);
                    router.go(-1);
                },
                {
                    success: "تم تعديل المادة  بنجاح",
                    error: "فشل تعديل المادة ",
                }
            );
        },
        ExportStudentsBySubjectIdToExcel({ commit }, id) {
            return axiosIns
                .get(
                    `Subject/Dash/ExportStudentsBySubjectIdToExcel?subjectId=${id}`,
                    {
                        responseType: "blob", // Set the response type to blob
                    }
                )
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                    });

                    const file_name = `Inventory_${new Date()
                        .toISOString()
                        .slice(0, 10)}.xlsx`;

                    const a = document.createElement("a");
                    a.href = window.URL.createObjectURL(blob);
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);

                    // Commit an action after successful export
                    commit("ExportToExcel", id);

                    console.log("تم تحميل ملف المشتركين");
                })
                .catch((error) => {
                    console.error("فشل تحميل ملف المشتركين");
                    throw error; // Propagate the error for further handling if needed
                });
        },

        deleteSubject({ commit }, ids) {
            api.delete(
                `Subject/Dash/Delete `,
                () => {
                    commit("deleteSubjects", ids);
                    router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف  المادة",
                    success: "تم حذف المواد بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
