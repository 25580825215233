import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";
import router from "@/router";
export default {
    state: {
        filterVal: "",
        packageList: [],
        codeList: [],
        codesDto: {},
        packageDto: {
            id: "",
            name: "",
            price: 0,
            description: "",
            endDate: null,
            codesActiveCount: 0,
            codesCount: 0,
            isHidden: false,
            period: 0,
            dateCreated: "",
            subjectIds: [],
            courseIds: [],
            codes: [
                {
                    discount: "",
                    packageId: "",
                    period: 0,
                },
            ],
        },
        statusDetailsPackage: [
            { id: false, name: "فعالة", color: "success" },
            { id: true, name: "ملغية ", color: "danger" },
        ],

        statusCode: [
            { value: true, text: "فعالة", color: "success" },
            { value: false, text: "غير مفعل", color: "danger" },
        ],

        statusPackage: [
            { value: false, text: "فعالة", color: "success" },
            { value: true, text: "ملغية ", color: "danger" },
        ],

        packageOptions: [
            {
                id: "Subject",
                name: "حزمة مواد  ",
            },
            {
                id: "Course",
                name: "حزمة كورسات",
            },
        ],
        columns: [
            {
                label: " اسم الحزمة ",
                sortable: true,
                field: "name",
            },
            {
                label: "  تابعة ل ",
                sortable: false,
                field: "faculties",
            },
            {
                label: "  عدد الرموز ",
                type: "number",
                field: "codesCount",
            },
            {
                label: "عدد الرموز المفعلة  ",
                type: "number",
                field: "codesActiveCount",
            },
            {
                label: "تاريخ الإنشاء",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "كلفة الحزمة ",
                type: "number",
                field: "price",
            },
            {
                label: "حالة الحزمة ",
                type: "boolean",
                field: "isHidden",
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false,
            },
        ],
        columnsCode: [
            {
                label: " الرمز ",
                field: "code",
            },
            {
                label: "   اسم الطالب ",
                field: "studentName",
            },
            {
                label: "  تاريخ الاشتراك  ",
                field: "startDate",
            },
            {
                label: "حالة الاشتراك    ",
                field: "isActive",
            },
            {
                label: "القيمة المدفوعة ",
                field: "price",
            },
            {
                label: "اسم المندوب",
                field: "createdBy",
            },
            {
                label: "تاريخ نهاية الاشتراك  ",
                field: "endDate",
            },
        ],
        unActivateCode: [
            {
                id: "",
                lable: "",
            },
        ],
        studentName: [
            {
                id: "",
                name: "",
            },
        ],
    },
    getters: {
        packages({ packageList }, getter, { filter }) {
            return packageList.filter((el) => {
                return getSearchResultOnRow(filter, el);
            });
        },
        filterCodes(state) {
            if (state.filterVal) {
                return state.packageDto.codes.filter((ele) => {
                    return (
                        ele.code
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.price
                            .toString()
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.studentName
                            ?.toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.createdBy
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase())
                    );
                });
            } else return state.packageDto.codes;
        },
    },
    mutations: {
        Get_Students_Name(state, payload) {
            state.studentName = payload;
        },
        Get_Activated_Codes(state, payload) {
            state.unActivateCode = payload;
        },
        Get_Package_List(state, payload) {
            state.packageList = payload;
        },
        Add_Package(state, payload) {
            state.packageList.unshift(payload);
        },
        Get_Details_Package(state, payload) {
            state.packageDto = { ...payload };
        },
        Update_Package(state, payload) {
            let dto = state.packageList.find((ele) => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        Delete_Package(state, payload) {
            state.packageList = state.packageList.filter(
                (el) => !payload.includes(el.id)
            );
        },
        Add_Code(state, payload) {
            state.packageDto.codes.unshift(payload);
        },
        Delete_Code(state, payload) {
            state.packageDto.codes = state.packageDto.codes.filter(
                (el) => !payload.includes(el.id)
            );
        },
        Reset_Code_Dto(state) {
            Object.assign(state.packageList, {
                packageId: "",
                discount: "",
                period: "",
            });
        },
        Filter_Codes(state, payload) {
            state.filterVal = payload;
        },
    },
    actions: {
        GetAllUnActivatedCodes({ commit }) {
            api.get("Package/Dash/GetAllUnActivatedCodes", ({ data }) => {
                commit("Get_Activated_Codes", data);
            });
            console.log("ddddddddddddd");
        },
        getStudentsName({ commit }, facultyId) {
            let facQuery = "";
            if (facultyId) facQuery = `?facultyId=${facultyId}`;
            api.get(`Student/Dash/GetNames${facQuery}`, ({ data }) => {
                commit("Get_Students_Name", data);
            });
        },

        getPackageList({ commit },payload) {
            let facQuery = "";
            if (payload) facQuery = `?search=${payload}`;
            api.get(`Package/Dash/GetAll${facQuery}`, ({ data }) => {
                commit("Get_Package_List", data);
            });
        },
        getPackageDetails({ commit }, id) {
            api.get(`Package/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Package", data);
            });
        },
        addPackage({ commit }, payload) {
            api.post(
                "Package/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Package", data);
                },
                {
                    success: "تم اضافة الحزمة بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },
        updatePackage({ commit }, payload) {
            api.post(
                "Package/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Package", data);
                    router.go(-1);
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات الحزمة",
                    success: "تم التعديل بنجاح  ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },
        ActivateStudentCode({ commit }, { activateCode, studentId }) {
            api.post(
                `Student/Dash/ActivateStudentCode?activateCode=${activateCode}&studentId=${studentId}`,
                ({ data }) => {
                    commit("ActivateStudentCode", data);
                },
                {
                    success: "تمت الاضافة بنجاح",
                    error: "فشل الاضافة.. يرجى إعادة المحاولة",
                }
            );
        },
        deletePackage({ commit }, ids) {
            api.delete(
                `Package/Dash/Delete`,
                () => {
                    commit("Delete_Package", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحزم المحددة",
                    success: "تم حذف الحزم بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        addCode({ commit }, payload) {
            api.post(
                "Package/Dash/AddCode",
                payload,
                ({ data }) => {
                    commit("Add_Code", data);
                    router.push("/admin/codes");
                },
                {
                    success: "تم اضافة الرمز بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteCode({ commit }, ids) {
            api.delete(
                `Package/Dash/DeleteCode`,
                () => {
                    commit("Delete_Code", ids);
                    router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الرموز المحددة",
                    success: "تم حذف الرموز بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
